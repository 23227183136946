.header {
  text-align: center;
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar > .btn {
  margin-right: 1rem;
}

.font-link {
  font-family: 'Playfair Display', serif;
  font-size: 1.2rem;
}

.font-menu {
  font-family: 'Fjalla One', sans-serif;
}

.font-logo {
  font-family: 'Tangerine', cursive;
}

.font-promise {
  font-family: 'Kdam Thmor Pro', sans-serif;
}

.font-story {
  font-family: 'Indie Flower', cursive;
  font-size: 1.3rem;
}

.font-staking {
  font-family: 'Playfair Display', serif;
}

.bg-arthur {
  background-image: url("https://skranf.me/apthypication/Arthurr.png");
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: top right;
}

.bg-goddess {
  background-image: url("https://skranf.me/apthypication/Goddess.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.bg-lady {
  background-image: url("https://skranf.me/apthypication/lady.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: top left;
}

.page{
  padding-top: 15vh;
  height: 100vh; /* For 100% screen height */
}

.page-story{
  padding-top: 15vh;
  height: 300vh; /* For 100% screen height */
}

.page-about{
  padding-top: 15vh;
  height: 200vh; /* For 100% screen height */
}

.page-promise{
  padding-top: 15vh;
  height: 200vh; /* For 100% screen height */
}

.page-staking{
  padding-top: 15vh;
  height: 125vh; /* For 100% screen height */
}

.bg-ange {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh; /* For 100% screen height */
  width:  100vw; 
  background-image: url("https://skranf.me/apthypication/Ange.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom left;
}

.bg-goddess {
  position: absolute;
  height: 120vh;
  width:  100vw; 
  background-image: url("https://skranf.me/apthypication/Goddess.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom;
}


.bg-footer {
  position: absolute;
  height: 100vh;
  width:  100vw; 
  background-image: url("https://skranf.me/apthypication/Footer.png");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: bottom;
}

::-webkit-scrollbar {
  width: 0px;
}

.assets {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding: 20px 0;
}

.asset {
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-left: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}